import React from "react";

export default function ServicesComponent() {
  return (
    <section className="services-section-two">
      <div
        className="bg-image"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      />
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="sub-title">SPECIALISE IN THE TRANSPORTATION</span>
          <h2>Specialist logistics services</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="service-block-two">
                <div className="inner-box">
                  <div className="image-box">
                    <i className="icon flaticon-forklift" />
                    <figure className="image">
                      <a href="/services" className="lightbox-image">
                        <img
                          src="images/service/loading-unloading-lifting.jpg"
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="content-box">
                    <span className="sub-title">
                      Services for Safe and Efficient Cargo Handling.
                    </span>
                    <h4 className="title">
                      <a href="/services">
                        Loading, Unloading, and Lifting Solutions
                      </a>
                    </h4>
                    <a href="/services" className="read-more">
                      Read More <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-block-two">
                <div className="inner-box">
                  <div className="image-box">
                    <i className="icon flaticon-cargo-1" />
                    <figure className="image">
                      <a href="/services" className="lightbox-image">
                        <img
                          src="images/service/installation-large-machinery.jpg"
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="content-box">
                    <span className="sub-title">
                      Placement for Commercial and Industrial Settings.
                    </span>
                    <h4 className="title">
                      <a href="/services">
                        Professional Installation of Large Machinery
                      </a>
                    </h4>
                    <a href="/services" className="read-more">
                      Read More <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-block-two">
                <div className="inner-box">
                  <div className="image-box">
                    <i className="icon flaticon-delivery-truck-4" />
                    <figure className="image">
                      <a href="/services" className="lightbox-image">
                        <img
                          src="images/service/cargo-transportation.jpg"
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="content-box">
                    <span className="sub-title">
                      Best Practices for Delivery and Installation.
                    </span>
                    <h4 className="title">
                      <a href="/services">
                        High Value Cargo and Machinery Transportation
                      </a>
                    </h4>
                    <a href="/services" className="read-more">
                      Read More <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
