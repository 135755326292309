import React from "react";

export default function AboutCompanyComponent() {
  return (
    <section className="about-section-two style-two">
      <div className="anim-icons">
        <div className="float-image wow fadeInRight">
          <img
            src="images/home/cargo-handling.webp"
            alt="DG Upali Cargo Handling"
          />
        </div>
        <div className="float-text wow zoomInRight">DGUPALI</div>
      </div>
      <div className="auto-container">
        <div className="row">
          <div
            className="content-column col-lg-6 col-md-12 col-sm-12 order-2 wow fadeInRight"
            data-wow-delay="600ms"
          >
            <div className="inner-column">
              <div className="sec-title">
                <span className="sub-title">About Company</span>
                <h2>Unlocking Efficiency, Powered by Innovation</h2>
                <h4>
                  Your Trusted Logistics Partner for Seamless Solutions Since
                  1995
                </h4>
                <div className="text">
                  "We provide efficient logistics solutions that leverage
                  advanced technology, delivering exceptional services and
                  customized solutions to optimize supply chain operations,
                  reduce transportation and logistics costs, and ensure complete
                  transparency."
                </div>
              </div>
              <div className="founder-info">
                <div className="thumb">
                  <img
                    src="images/home/ceo-thumbnail.webp"
                    alt="D G Upali Ananda Siriwardhana"
                  />
                </div>
                <h5 className="name">D G Upali Ananda Siriwardhana</h5>
                <span className="designation">
                  Founder &amp; Managing Director
                </span>
              </div>
            </div>
          </div>
          {/* Image Column */}
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <figure className="image-1">
                <img
                  src="images/home/expert-team.webp"
                  alt="DG Upali Expert Team"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
