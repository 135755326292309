import React from "react";

export default function BannerComponent() {
  return (
    <section className="banner-section">
      <div className="banner-carousel">
        {/* Slide Item */}
        <div className="slide-item">
          <div
            className="bg-image"
            style={{ backgroundImage: "url(images/main-slider/3.webp)" }}
          />
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="content-box">
                  <h1 className="title animate-3">
                    Most trusted logistics
                    <span className="colored"> &amp;</span> placement services
                  </h1>
                  <div className="btn-box animate-4">
                    <a
                      href="/services"
                      className="theme-btn btn-style-one alternate"
                    >
                      <span className="btn-title">Explore More</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="image-box">
                  <figure className="image animate-5 animate-x">
                    <img src="images/home/dg-upali-truck.webp" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
