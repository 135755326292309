import React from "react";

export default function CallToActionComponent() {
  return (
    <section
      className="call-to-action style-two"
      style={{ backgroundImage: "url(images/background/2.webp)" }}
    >
      <div className="auto-container">
        <div className="outer-box">
          <div className="sec-title text-center light mb-0">
            <div className="sub-title">Get in touch with us anytime</div>
            <h1>
              Looking for the best <br />
              logistics transport & machinery placement &nbsp;
              <span className="highlighted">service?</span>
            </h1>
            <a
              href="tel:+94777340980"
              className="theme-btn btn-style-one hvr-light"
            >
              <span className="btn-title">Get A Quote</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
