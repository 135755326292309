import React from "react";

export default function MainHeaderComponent() {
  return (
    <header className="main-header header-style-two">
      {/* Header Top */}
      <div className="header-top">
        <div className="top-left">
          {/* Info List */}
          <ul className="list-style-one">
            <li>
              <i className="fa fa-map-marker-alt" /> No.55/16, Vauxhall Lane,
              Colombo 02
            </li>
            <li>
              <i className="fa fa-clock" /> Mon - Sat: 8am - 5pm
            </li>
            <li>
              <i className="fa fa-phone-volume" />{" "}
              <a href="tel:+94777340980">+94 777 340 980</a>
            </li>
            <li>
              <i className="icon lnr-icon-envelope1" />{" "}
              <a href="mailto:logistics@dgupali.lk">logistics@dgupali.lk</a>
            </li>
          </ul>
        </div>
        <div className="top-right">
          <ul className="social-icon-one">
            <li>
              <a href="https://www.facebook.com/dgupaliandsons">
                <span className="fab fa-facebook-square" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* Header Top */}
      {/* Header Lower */}
      <div className="header-lower">
        {/* Main box */}
        <div className="main-box">
          <div className="logo-box">
            <div className="logo">
              <a href="/">
                <img src="images/logo/dg-upali.png" alt="" title="D G Upali" />
              </a>
            </div>
          </div>
          {/*Nav Box*/}
          <div className="nav-outer">
            <div className="nav-outer">
              <nav className="nav main-menu">
                <ul className="navigation">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/showcase">Showcase</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </nav>
              {/* Main Menu End*/}
              <div className="outer-box">
                <a
                  href="tel:+94777340980"
                  className="theme-btn btn-style-two alternate hvr-light"
                >
                  <span className="btn-title">Get A Quote</span>
                </a>
                {/* Mobile Nav toggler */}
                <div className="mobile-nav-toggler">
                  <span
                    className="icon lnr-icon-bars"
                    onClick={() => {
                      document.body.classList.add("mobile-menu-visible");
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Main Menu End*/}
          </div>
        </div>
      </div>
      {/* End Header Lower */}

      {/* Mobile Menu  */}
      <div className="mobile-menu">
        <div className="menu-backdrop" />
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
        <nav className="menu-box">
          <div className="upper-box">
            <div className="nav-logo">
              <a href="/">
                <img
                  src="images/dg-upali-logo-2.png"
                  alt=""
                  title="D G Upali"
                />
              </a>
            </div>
            <div className="close-btn">
              <i
                className="icon fa fa-times"
                onClick={() => {
                  document.body.classList.remove("mobile-menu-visible");
                }}
              />
            </div>
          </div>
          <ul className="navigation clearfix">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/showcase">Showcase</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
          <ul className="contact-list-one">
            <li>
              {/* Contact Info Box */}
              <div className="contact-info-box">
                <i className="icon lnr-icon-phone-handset" />
                <span className="title">Call Now</span>
                <a href="tel:+92880098670">+94 777 340 980</a>
              </div>
            </li>
            <li>
              {/* Contact Info Box */}
              <div className="contact-info-box">
                <span className="icon lnr-icon-envelope1" />
                <span className="title">Send Email</span>
                <a href="mailto:help@company.com">logistics@dgupali.lk</a>
              </div>
            </li>
            <li>
              {/* Contact Info Box */}
              <div className="contact-info-box">
                <span className="icon lnr-icon-clock" />
                <span className="title">Send Email</span>
                Mon - Sat: 8am - 5pm
              </div>
            </li>
          </ul>
          <ul className="social-links">
            <li>
              <a href="https://www.facebook.com/dgupaliandsons">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/* End Mobile Menu */}
      {/* Sticky Header  */}
      <div className="sticky-header">
        <div className="auto-container">
          <div className="inner-container">
            {/*Logo*/}
            <div className="logo">
              <a href="/" title="">
                <img
                  src="images/dg-upali-logo-2.png"
                  alt=""
                  title="D G Upali"
                />
              </a>
            </div>
            {/*Right Col*/}
            <div className="nav-outer">
              {/* Main Menu */}
              <nav className="main-menu">
                <div className="navbar-collapse show collapse clearfix">
                  <ul className="navigation clearfix">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/showcase">Showcase</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
              {/*Mobile Navigation Toggler*/}
              <div className="mobile-nav-toggler">
                <span className="icon lnr-icon-bars" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Sticky Menu */}
    </header>
  );
}
