import React from "react";

export default function TeamComponent() {
  return (
    <section className="team-section">
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="sub-title">Our Team</span>
          <h2>Meet expert team</h2>
        </div>
        <div className="row">
          <div className=".d-none .d-lg-block .d-xl-none col"></div>
          {/* Team block */}
          <div className="team-block col-lg-5 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="image-box">
                <figure className="image">
                  <a href="/about">
                    <img
                      src="images/team/founder.jpg"
                      alt="D G Upali Ananda Siriwardhana"
                    />
                  </a>
                </figure>
                <div className="social-links">
                  <a href="https://www.facebook.com/dgupaliandsons">
                    <i className="fab fa-facebook-f" />
                  </a>
                </div>
              </div>
              <div className="info-box">
                <span className="designation">Founder & Managing Director</span>
                <h4 className="name">
                  <a href="/about">D G Upali Ananda Siriwardhana</a>
                </h4>
              </div>
            </div>
          </div>
          {/* Team block */}
          <div className="team-block col-lg-5 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="image-box">
                <figure className="image">
                  <a href="/about">
                    <img
                      src="images/team/md.jpg"
                      alt="D G Udesh Nuwan Siriwardhana"
                    />
                  </a>
                </figure>
                <div className="social-links">
                  <a href="https://www.linkedin.com/in/udesh-nuwan-siriwardhana-30964626a/?originalSubdomain=lk">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="https://www.facebook.com/dgupaliandsons">
                    <i className="fab fa-facebook-f" />
                  </a>
                </div>
              </div>
              <div className="info-box">
                <span className="designation">
                  Co-founder & Managing Director
                </span>
                <h4 className="name">
                  <a href="/about">D G Udesh Nuwan Siriwardhana</a>
                </h4>
              </div>
            </div>
          </div>
          <div className=".d-none .d-lg-block .d-xl-none col"></div>
        </div>
      </div>
    </section>
  );
}
