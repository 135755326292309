import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderComponent from "./Components/Common/HeaderComponent";
import FooterComponent from "./Components/Common/FooterComponent";
import ScrollToTopComponent from "./Components/Common/ScrollToTopComponent";
import Home from "./Components/Home";
import PageNotFound from "./Components/404";
import About from "./Components/About";
import Services from "./Components/Services";
import Showcase from "./Components/Showcase";
import Contact from "./Components/Contact";

export default function App() {
  return (
    <BrowserRouter>
      <div className="page-wrapper">
        {/* <PreloaderComponent /> */}
        <HeaderComponent />
        <Routes>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="showcase" element={<Showcase />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <FooterComponent />
      </div>
      <ScrollToTopComponent />
    </BrowserRouter>
  );
}
