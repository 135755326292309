import React from "react";

function ProjectCarouselItemComponent() {
  const imageList = [
    {
      image: "access-tower-2-colombo-2.webp",
      alt: "access-tower-2-colombo-2",
      title: "Access Tower 02",
      details: "Colombo 02",
    },
    {
      image: "amw-union-place-colombo.webp",
      alt: "amw-union-place-colombo",
      title: "Associated Motorways",
      details: "Colombo 02",
    },
    {
      image: "cinnamon-red-kandy.webp",
      alt: "cinnamon-red-kandy",
      title: "Cinnamon Red",
      details: "Kandy",
    },
    {
      image: "grand-hyatt-colombo-3.webp",
      alt: "grand-hyatt-colombo-3",
      title: "Grand Hyatt",
      details: "Colombo 03",
    },
    {
      image: "ceat-kelani-international-peliyagoda.webp",
      alt: "ceat-kelani-international-peliyagoda",
      title: "CEAT Kelani International",
      details: "Peliyagoda",
    },
    {
      image: "itc-colombo-one-colombo-1.webp",
      alt: "itc-colombo-one-colombo-1",
      title: "ITC Colombo One",
      details: "Colombo 01",
    },
    {
      image: "mas-intimates-casualline-mawathagama.webp",
      alt: "mas-intimates-casualline-mawathagama",
      title: "MAS Intimates Casualline",
      details: "Mawathagama",
    },
    {
      image: "ministery-of-education-battaramulla.webp",
      alt: "ministery-of-education-battaramulla",
      title: "Ministery of Education",
      details: "Battaramulla",
    },
    {
      image: "ministry-of-defense-akuregoda.webp",
      alt: "ministry-of-defense-akuregoda",
      title: "Ministry of Defense",
      details: "Akuregoda",
    },
    {
      image: "iconic-building-rajagiriya.webp",
      alt: "iconic-building-rajagiriya",
      title: "Iconic Building",
      details: "Rajagiriya",
    },
    {
      image: "itc-colombo-one-hotel-colombo-1.webp",
      alt: "itc-colombo-one-hotel-colombo-1",
      title: "ITC Colombo One Hotel",
      details: "Colombo 01",
    },
    {
      image: "vallibel-finance-colombo-3.webp",
      alt: "vallibel-finance-colombo-3",
      title: "Vallibel Finance",
      details: "Colombo 03",
    },
  ];

  return imageList.map((image, key) => {
    return (
      <div className="project-block" key={key}>
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
              <a href="/showcase" className="lightbox-image">
                <img
                  src={`images/showcase/carousel/${image.image}`}
                  alt={image.alt}
                />
              </a>
            </figure>
          </div>
          <div className="content-box">
            <span className="sub-title">{image.title}</span>
            <h4 className="title">
              <span>{image.details}</span>
            </h4>
          </div>
        </div>
      </div>
    );
  });
}

export default ProjectCarouselItemComponent;
