import React from "react";

export default function ImageColumnComponent() {
  const numOfYears = new Date().getFullYear() - 1995;
  return (
    <div className="image-column col-lg-6 col-md-12 col-sm-12">
      <div
        className="inner-column wow fadeInLeft animated"
        style={{ visibility: "visible", animationName: "fadeInLeft" }}
      >
        <figure
          className="image-1 wow fadeInUp animated"
          style={{ visibility: "visible", animationName: "fadeInUp" }}
        >
          <img src="images/about/safety.webp" alt="" />
        </figure>
        <figure
          className="image-2 wow fadeInRight animated"
          style={{
            visibility: "visible",
            animationName: "fadeInRight",
          }}
        >
          <img src="images/about/employee.webp" alt="" />
        </figure>
        <div className="experience bounce-y">
          <strong>
            <i className="icon flaticon-global" /> {numOfYears} &nbsp; Years of
          </strong>
          Working Experience
        </div>
      </div>
    </div>
  );
}
