import React from "react";
import { Link } from "react-router-dom";

export default function index() {
  return (
    <section className>
      <div className="auto-container pt-120 pb-70">
        <div className="row">
          <div className="col-xl-12">
            <div className="error-page__inner">
              <div className="error-page__title-box mt-3">
                <img src="images/resource/404.jpg" alt="" />
                <h3 className="error-page__sub-title mt-3">Page not found!</h3>
              </div>
              <p className="error-page__text">
                Sorry we can't find that page! The page you are looking <br />{" "}
                for was never existed.
              </p>
              <Link to="/" className="theme-btn btn-style-one shop-now">
                <span className="btn-title">Back to Home</span>
              </Link>
              {/* <a href="/" className="theme-btn btn-style-one shop-now"></a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
