import React from "react";
import BannerComponent from "./BannerComponent";
import OurValuesComponent from "./OurValuesComponent";
import AboutCompanyComponent from "./AboutCompanyComponent";
import ServicesComponent from "./ServicesComponent";
import ProjectsComponent from "./ProjectsComponent";
import TeamComponent from "./TeamComponent";
import CallToActionComponent from "./CallToActionComponent";

export default function HomeComponent() {
  return (
    <>
      <BannerComponent />

      <OurValuesComponent />

      <AboutCompanyComponent />

      <ServicesComponent />

      <CallToActionComponent />

      <TeamComponent />

      <ProjectsComponent />
    </>
  );
}
