import React from "react";
import ContactFormComponent from "./ContactFormComponent";

export default function Contact() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title mt-5">Contact Us</h1>
          </div>
        </div>
      </section>
      <section className="contact-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="contact-details__right">
                <div className="sec-title my-3">
                  <span className="sub-title">Need any help?</span>
                  <h2>Get in touch with us</h2>
                  <div className="text">
                    "At D G Upali & Sons, we're committed to providing reliable
                    transportation and logistics services to keep your business
                    moving. Our experienced team ensures timely delivery of your
                    shipments, and we're always available to address any
                    questions or concerns you may have. Contact us today to
                    learn more about how we can help with your transportation
                    needs."
                  </div>
                </div>
                <ul className="list-unstyled contact-details__info">
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-phone-plus" />
                    </div>
                    <div className="text">
                      <h6 className="text-white">Have any question?</h6>
                      <a href="tel:+94777340980">+94 777 340 980</a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-envelope1" />
                    </div>
                    <div className="text">
                      <h6 className="text-white">Write email</h6>
                      <a href="mailto:logistics@dgupali.lk">
                        logistics@dgupali.lk
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-location" />
                    </div>
                    <div className="text">
                      <h6 className="text-white">Visit anytime</h6>
                      <span>No.55/16, Vauxhall Lane, Colombo 02</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="sec-title my-3">
                <span className="sub-title">Send us email</span>
                <h2>Feel free to write</h2>
                <ContactFormComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            {/* Google Map HTML Codes */}
            <iframe
              title="dgupali"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7921.442346029351!2d79.8486397348877!3d6.923896500000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25902ed78239f%3A0x169fdb7df5ac36dc!2sD%20G%20Upali%20%26%20Sons%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1681983958520!5m2!1sen!2slk"
              data-tm-width="100%"
              height={500}
              frameBorder={0}
              allowFullScreen
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
    </>
  );
}
