import React from "react";

export default function FooterComponent() {
  const year = new Date().getFullYear();
  return (
    <footer className="main-footer">
      <div
        className="bg-image"
        style={{ backgroundImage: "url(images/background/5.jpg)" }}
      />
      <div className="anim-icons">
        <span className="icon icon-plane-3" />
      </div>
      {/* Contact info */}
      <div className="contacts-outer">
        <div className="auto-container">
          <div className="row">
            {/* Contact Info Block */}
            <div className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight">
              <div className="inner-box">
                <div className="icon-box">
                  <i className="icon flaticon-international-shipping-2" />
                </div>
                <h4 className="title">Address</h4>
                <div className="text">No.55/16, Vauxhall Lane, Colombo 02</div>
              </div>
            </div>
            {/* Contact Info Block */}
            <div
              className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight"
              data-wow-delay="300ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <i className="icon flaticon-stock-1" />
                </div>
                <h4 className="title">Contact</h4>
                <div className="text">
                  <a href="mailto:logistics@dgupali.lk">logistics@dgupali.lk</a>
                  <br />
                  <a href="tel:+94777340980">+94 777 340 980</a>
                </div>
              </div>
            </div>
            {/* Contact Info Block */}
            <div
              className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight"
              data-wow-delay="600ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <i className="icon flaticon-24-hours-2" />
                </div>
                <h4 className="title">Timing</h4>
                <div className="text">Mon - Sat: 8am - 5pm, Sunday: CLOSED</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Contact info */}
      {/*Widgets Section*/}
      <div className="widgets-section">
        <div className="auto-container">
          <div className="row">
            {/*Footer Column*/}
            <div className="footer-column col-xl-3 col-lg-12 col-md-6 col-sm-12">
              <div className="footer-widget about-widget">
                <div className="logo">
                  <a href="/">
                    <img src="images/logo-2-new.png" alt="" />
                  </a>
                </div>
                <div className="text">
                  We work with a passion of taking challenges and creating new
                  ones in logistic sector.
                </div>
                <a
                  href="/about"
                  className="theme-btn btn-style-one hvr-light small"
                >
                  <span className="btn-title">About</span>
                </a>
              </div>
            </div>
            {/*Footer Column*/}
            <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget">
                <h3 className="widget-title">Service</h3>
                <ul className="user-links">
                  <li>
                    <a href="/services">Loading &amp; Unloading Services</a>
                  </li>
                  <li>
                    <a href="/services">Lifting &amp; Unloading Services</a>
                  </li>
                  <li>
                    <a href="/services">
                      Professional Machinery Placement Services
                    </a>
                  </li>
                  <li>
                    <a href="/services">Transportation of Machinery</a>
                  </li>
                </ul>
              </div>
            </div>
            {/*Footer Column*/}
            <div className="footer-column col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget gallery-widget">
                <h3 className="widget-title">Projects</h3>
                <div className="widget-content">
                  <div className="outer clearfix">
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/1.webp" alt="" />
                      </a>
                    </figure>
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/2.webp" alt="" />
                      </a>
                    </figure>
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/3.webp" alt="" />
                      </a>
                    </figure>
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/4.webp" alt="" />
                      </a>
                    </figure>
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/5.webp" alt="" />
                      </a>
                    </figure>
                    <figure className="image">
                      <a href="/showcase">
                        <img src="images/showcase/thumbnails/6.webp" alt="" />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            {/*Footer Column*/}
            <div className="footer-column col-xl-3 col-lg-5 col-md-6 col-sm-12">
              <div className="footer-widget">
                <h3 className="widget-title">Newsletter</h3>
                <div className="widget-content">
                  <div className="subscribe-form">
                    <div className="text">
                      Subscribe our newsletter to get our latest update &amp;
                      news
                    </div>
                    <form method="post" action="/">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="email"
                          defaultValue=""
                          placeholder="Email Address"
                          required=""
                        />
                        <button
                          type="button"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">
                            <i className="fa fa-paper-plane" />
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Footer Bottom*/}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="inner-container">
            <div className="copyright-text">
              <p>
                Copyright © {year} by{" "}
                <a href="https://dgupali.lk/">D G Upali & Sons</a> | Crafted
                with love by <a href="https://catanolabs.com/">Catanolabs</a>
              </p>
            </div>
            <ul className="social-icon-two">
              <li>
                <a href="https://www.facebook.com/dgupaliandsons">
                  <i className="fab fa-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
