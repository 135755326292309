import React, { useState } from "react";

export default function ContactFormComponent(props) {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
    setErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, phone, message } = formValues;
    // Validate the form inputs
    const validationErrors = {};
    if (!name.trim()) {
      validationErrors.name = "Please enter your name";
    }
    if (!email.trim()) {
      validationErrors.email = "Please enter your email";
    }
    if (!subject.trim()) {
      validationErrors.subject = "Please enter your subject";
    }
    if (!phone.trim()) {
      validationErrors.phone = "Please enter your phone number";
    }
    if (!message.trim()) {
      validationErrors.message = "Please enter a message";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const body = `Hi, ${message} <br/> contact details name ${name} email ${email} phone ${phone}`;
    const mailtoUrl = `mailto:logistics@dgupali.lk?from=${email}&subject=${subject}&body="${body}"`;
    window.open(mailtoUrl);
  };

  return (
    <form id="contact_form" name="contact_form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <div className="mb-3">
            <label>
              Name <small>*</small>
            </label>
            <input
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              type="text"
              placeholder="Enter Name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="mb-3">
            <label>
              Email <small>*</small>
            </label>
            <input
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              type="email"
              placeholder="Enter Email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="mb-3">
            <label>
              Subject <small>*</small>
            </label>
            <input
              className={`form-control required ${
                errors.subject ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Enter Subject"
              name="subject"
              value={formValues.subject}
              onChange={handleInputChange}
            />
            {errors.subject && (
              <div className="invalid-feedback">{errors.subject}</div>
            )}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="mb-3">
            <label>Phone</label>
            <input
              className={`form-control required ${
                errors.phone ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Enter Phone"
              name="phone"
              value={formValues.phone}
              onChange={handleInputChange}
            />
            {errors.phone && (
              <div className="invalid-feedback">{errors.phone}</div>
            )}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label>Message</label>
        <textarea
          className={`form-control required ${
            errors.message ? "is-invalid" : ""
          }`}
          rows={5}
          placeholder="Enter Message"
          name="message"
          value={formValues.message}
          onChange={handleInputChange}
        />
        {errors.message && (
          <div className="invalid-feedback">{errors.message}</div>
        )}
      </div>
      <div className="mb-3">
        <button type="submit" className="theme-btn btn-style-one mx-1 my-1">
          <span className="btn-title">Send message</span>
        </button>
        <button type="reset" className="theme-btn btn-style-one mx-1 my-1">
          <span className="btn-title">Reset</span>
        </button>
      </div>
    </form>
  );
}
