import React from "react";
// import TestimonialComponent from "./TestimonialComponent";
import ImageColumnComponent from "./ImageColumnComponent";

export default function AboutComponent() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title mt-5">About Us</h1>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="anim-icons">
          <div
            className="float-image wow fadeInRight animated"
            style={{ visibility: "visible", animationName: "fadeInRight" }}
          ></div>
          <span className="icon icon-dotted-map zoom-one" />
        </div>
        <div className="auto-container">
          <div className="row">
            <div
              className="content-column col-lg-6 col-md-12 col-sm-12 order-2 wow fadeInRight animated"
              data-wow-delay="600ms"
              style={{
                visibility: "visible",
                animationDelay: "600ms",
                animationName: "fadeInRight",
              }}
            >
              <div className="inner-column">
                <div className="sec-title">
                  <span className="sub-title">
                    The Inspiring Story of D G Upali & Sons
                  </span>
                  <div className="text text-white">
                    In 1980, a young man named D.G.Upali Siriwardhana began his
                    journey in the logistics industry, working tirelessly for a
                    logistics company. Despite his dedication to his job, he
                    always had a dream of starting his own logistics company
                    someday. Over the years, he worked hard to save up the
                    necessary funds for vehicles and machinery, while constantly
                    learning and expanding his knowledge of the industry.
                    Finally, in 1995, he took the bold step of starting his own
                    company, a sole proprietorship named D.U.A.Siriwardhana &
                    Sons.
                  </div>
                  <div className="text text-white">
                    With unwavering courage and determination, D.G.Upali
                    Siriwardhana and his team quickly built a reputation for
                    providing high-quality logistics services, earning the trust
                    and loyalty of customers across the country. In 2020, with
                    the support and assistance of his son, D.G.Udesh Nuwan
                    Siriwardhana, the company underwent a major restructuring
                    and was transformed into a private limited company under the
                    new name, D.G.Upali & Sons Private Limited.
                  </div>
                  <div className="text text-white">
                    Today, we are proud to continue our founder's legacy and
                    remain committed to providing our customers with exceptional
                    logistics solutions. Our team is constantly innovating and
                    adapting to meet the ever-changing needs of the industry,
                    while never forgetting the values of hard work, integrity,
                    and customer satisfaction that have guided us since our
                    founding.
                  </div>
                </div>
              </div>
            </div>
            <ImageColumnComponent />
          </div>
        </div>
      </section>
      <section className="mission-vision-section pt-0 pb-100">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="content-box">
                <div className="about-block">
                  <h4 className="title">Our Mission</h4>
                  <p className="text">
                    To provide reliable, efficient, and customer-focused
                    solutions that keep your cargo moving safely and on time.
                    We're committed to doing it with the utmost care,
                    transparency, and innovation while prioritizing the safety
                    of our employees, the environment, and the communities we
                    serve.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="content-box">
                <div className="about-block">
                  <h4 className="title">Our Vision</h4>
                  <p className="text">
                    To be the preferred logistics partner for businesses across
                    industries, providing cost-effective solutions that drive
                    their growth & success
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="outer-box"></div>
        </div>
      </section>
      {/* <TestimonialComponent /> */}
    </>
  );
}
