import React from "react";
import { projects } from "./projects";
import PhotoGallery from "./PhotoGallery";
export default function Showcase() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title mt-5">Showcase</h1>
          </div>
        </div>
      </section>
      <section className="showcase-section">
        {projects.map((project, key) => {
          return (
            <div className="mt-5" key={key}>
              <div className="sec-title text-center">
                <span className="sub-title">{project.name}</span>
                <h4 className="px-5">{project.des}</h4>
              </div>
              <div className="large-container">
                <div className="row">
                  <PhotoGallery photos={project.photos} />
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <div className="d-flex justify-content-center mb-5">
        <a
          href="https://www.facebook.com/dgupaliandsons"
          target="_blank"
          rel="noreferrer"
          className="theme-btn btn-style-one hvr-light small"
        >
          <span className="btn-title">
            Visit our Facebook page for the latest project
          </span>
        </a>
      </div>
    </>
  );
}
