import React from "react";

export default function ScrollToTopComponent() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scroll-to-top scroll-to-target" onClick={goToTop}>
      <span className="fa fa-angle-up" />
    </div>
  );
}
