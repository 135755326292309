import inqube1 from "../../projects/inqube/1-3-4.webp";
import inqube2 from "../../projects/inqube/2-4-4.webp";
import inqube3 from "../../projects/inqube/3-3-4.webp";
import inqube4 from "../../projects/inqube/4-4-4.webp";

import sixZeroSix1 from "../../projects/606/1-4-4.webp";
import sixZeroSix2 from "../../projects/606/2-3-4.webp";
import sixZeroSix3 from "../../projects/606/3-4-4.webp";
import sixZeroSix4 from "../../projects/606/4-3-4.webp";

import ceb1 from "../../projects/ceb-wathurugama/1-3-4.webp";
import ceb2 from "../../projects/ceb-wathurugama/2-4-4.webp";
import ceb3 from "../../projects/ceb-wathurugama/3-3-4.webp";
import ceb4 from "../../projects/ceb-wathurugama/4-4-4.webp";

import ceb5 from "../../projects/ceb-dehiwala/1-4-4.webp";
import ceb6 from "../../projects/ceb-dehiwala/2-3-4.webp";
import ceb7 from "../../projects/ceb-dehiwala/3-4-4.webp";
import ceb8 from "../../projects/ceb-dehiwala/4-3-4.webp";

import ggh1 from "../../projects/gampaha-gh/1-3-4.webp";
import ggh2 from "../../projects/gampaha-gh/2-4-4.webp";
import ggh3 from "../../projects/gampaha-gh/3-3-4.webp";
import ggh4 from "../../projects/gampaha-gh/4-4-4.webp";

export const projects = [
  {
    name: "Inqube at Ranala",
    des: "Unloading and placing chillers and AHU",
    photos: [
      {
        src: inqube1,
        width: 3,
        height: 4,
      },
      {
        src: inqube2,
        width: 1,
        height: 1,
      },
      {
        src: inqube3,
        width: 3,
        height: 4,
      },
      {
        src: inqube4,
        width: 1,
        height: 1,
      },
    ],
  },
  {
    name: "606 the Address",
    des: "Loading, Transporting, Unloading moving and placing 2 Cummins generators with 640KVA from ground floor to 7th floor through the ramp and 2 cummins generator with 1250kva 85 feet above  at  606",
    photos: [
      {
        src: sixZeroSix1,
        width: 1,
        height: 1,
      },
      {
        src: sixZeroSix2,
        width: 3,
        height: 4,
      },
      {
        src: sixZeroSix3,
        width: 1,
        height: 1,
      },
      {
        src: sixZeroSix4,
        width: 3,
        height: 4,
      },
    ],
  },
  {
    name: "CEB Yard at Wathurugama.",
    des: "Unloading 40ft electric glass insulators containers",
    photos: [
      {
        src: ceb1,
        width: 3,
        height: 4,
      },
      {
        src: ceb2,
        width: 1,
        height: 1,
      },
      {
        src: ceb3,
        width: 3,
        height: 4,
      },
      {
        src: ceb4,
        width: 1,
        height: 1,
      },
    ],
  },
  {
    name: "Ceylon Electricity Board, Distribution Division 04 Headquarters Dehiwala",
    des: "Loading, Transporting, Unloading & Placing GIS Panel",
    photos: [
      {
        src: ceb7,
        width: 1,
        height: 1,
      },
      {
        src: ceb8,
        width: 3,
        height: 4,
      },
      {
        src: ceb5,
        width: 1,
        height: 1,
      },
      {
        src: ceb6,
        width: 3,
        height: 4,
      },
    ],
  },
  {
    name: "Gampaha Genaral Hospital",
    des: "Unloading, Moving & Placing 3 Air Cool Chillers",
    photos: [
      {
        src: ggh1,
        width: 3,
        height: 4,
      },
      {
        src: ggh2,
        width: 1,
        height: 1,
      },
      {
        src: ggh3,
        width: 3,
        height: 4,
      },
      {
        src: ggh4,
        width: 1,
        height: 1,
      },
    ],
  },
];
