import React from "react";
import OwlCarousel from "react-owl-carousel";
import ProjectCarouselItemComponent from "./ProjectCarouselItemComponent";

export default function ProjectsComponent() {
  const options = {
    loop: true,
    margin: 20,
    nav: false,
    smartSpeed: 1500,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3500,
    navText: [
      '<span className="fa fa-long-arrow-alt-left"></span>',
      '<span className="fa fa-long-arrow-alt-right"></span>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <section className="project-section">
      <div
        className="bg-image"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      />
      <div className="large-container">
        <div className="sec-title text-center">
          <span className="sub-title">LATEST PROJECTS</span>
          <h2>Works across the SriLanka</h2>
        </div>
        {/* Prject Carousel */}
        <OwlCarousel
          className="project-carousel owl-carousel owl-theme"
          {...options}
        >
          <ProjectCarouselItemComponent />
        </OwlCarousel>
      </div>
    </section>
  );
}
