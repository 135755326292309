import React from "react";

export default function Services() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/1.jpg)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title mt-5">Our Special Services</h1>
          </div>
        </div>
      </section>
      <section className="services-section">
        <div className="auto-container">
          {/* Service Row */}
          <div className="row">
            <div
              className="service-block col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box ">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="images/service/loading-and-unloading.webp"
                      alt="DG Upali Loading and Unloading"
                    />
                  </figure>
                </div>
                <div className="content-box">
                  <i className="icon flaticon-forklift" />
                  <h4 className="title text-white">
                    Reliable Loading and Unloading Services
                  </h4>
                  <div className="text">
                    Safe and Efficient Transport of Goods to Different Locations
                  </div>
                </div>
              </div>
            </div>
            <div
              className="service-block col-xl-8 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated my-auto"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <p>
                Looking for reliable and efficient loading and unloading
                services? Look no further! We specialize in loading and
                unloading 20ft and 40ft containers, as well as flatbed trucks,
                to help businesses transport their goods safely and efficiently
                to different locations.
              </p>
              <p>
                Our team of professionals carefully plans the loading process to
                ensure the weight and size of the cargo is evenly distributed,
                preventing damage to the container or the goods during
                transport. With our specialized equipment, such as cranes and
                forklifts, we ensure that the cargo is handled safely and
                efficiently during unloading.
              </p>
            </div>
          </div>
          {/* Service Row */}
          <div className="row">
            <div
              className="service-block col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated order-1 order-md-2"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box ">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="images/service/lifting-and-unloading.webp"
                      alt="DG Upali Lifting and Unloading"
                    />
                  </figure>
                </div>
                <div className="content-box">
                  <i className="icon flaticon-crane-2" />
                  <h4 className="title text-white">
                    Lifting and Unloading Services for Heavy Items
                  </h4>
                  <div className="text">
                    Efficient and Safe Transportation of Machinery, and
                    Construction Materials to and from Tall Buildings
                  </div>
                </div>
              </div>
            </div>
            <div
              className="service-block col-xl-8 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated my-auto order-2 order-md-1"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <p>
                We also provide specialized lifting and unloading services for
                heavy or bulky items from tall buildings. Our experts thoroughly
                assess the building's architecture, safety features, and the
                type of items to be moved before the lifting process begins.
              </p>
              <p>
                We help businesses, construction companies, and individuals
                transport large items such as furniture, machinery, construction
                materials, or heavy equipment to or from tall buildings.
                Depending on the weight and size of the items, we use different
                types of lifting equipment and strictly follow safety protocols
                to avoid accidents or property damage.
              </p>
            </div>
          </div>
          {/* Service Row */}
          <div className="row">
            <div
              className="service-block col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box ">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="images/service/machinery-placement.webp"
                      alt="DG Upali Machinery Placement"
                    />
                  </figure>
                </div>
                <div className="content-box">
                  <i className="icon flaticon-crane-1" />
                  <h4 className="title text-white">
                    Professional Machinery Placement Services
                  </h4>
                  <div className="text">
                    Meticulous Planning and Expert Execution for Precise
                    Installation and Compliance with Safety Standards
                  </div>
                </div>
              </div>
            </div>
            <div
              className="service-block col-xl-8 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated my-auto"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <p>
                At our company, we understand the importance of proper placement
                and installation of large machinery in commercial and industrial
                settings. Our team of highly-trained professionals utilizes
                specialized tools and equipment to ensure safe and efficient
                installation, taking into consideration all relevant factors
                such as site assessment, measurements, and site engineer's
                method statement.
              </p>
              <p>
                We go the extra mile by ensuring timely and secure delivery of
                the equipment to the designated site. Using state-of-the-art
                cranes, hoists, and rigging equipment, we ensure precise
                positioning and secure installation of the machinery. We also
                make sure to connect the equipment to the relevant systems,
                conduct thorough tests, and ensure compliance with local
                regulations and safety standards.
              </p>
              <p>
                Our meticulous planning and attention to detail guarantee a
                high-level customer experience, with minimal disruption to your
                operations. We handle a wide range of machinery, including
                generators, transformers, chillers, boilers, injection molding
                machines, laundry machines, lathe machines, elevators,
                escalators, and bulk tanks. Trust us to handle your machinery
                placement needs with the utmost care and expertise.
              </p>
            </div>
          </div>
          {/* Service Row */}
          <div className="row">
            <div
              className="service-block col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated order-1 order-md-2"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box ">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="images/service/transportation-of-machinery.webp"
                      alt="DG Upali Transportation of Machinery"
                    />
                  </figure>
                </div>
                <div className="content-box">
                  <i className="icon flaticon-truck-1" />
                  <h4 className="title text-white">
                    Safe and Efficient Transportation of Machinery
                  </h4>
                  <div className="text">
                    Expert Planning and Execution for Smooth Delivery and
                    Installation
                  </div>
                </div>
              </div>
            </div>
            <div
              className="service-block col-xl-8 col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated my-auto order-2 order-md-1"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <p>
                Our team follows a comprehensive approach to ensure the safe and
                efficient transportation and placement of various machinery.
                Starting with the assessment of transportation requirements, we
                determine the best route, mode of transportation, and packaging
                to ensure secure transit.
              </p>
              <p>
                Upon arrival at the destination, our experts assess the site and
                determine the best location for the equipment based on space
                requirements, access to utilities, and safety considerations. We
                also unpack and assemble the equipment with specialized tools
                and high-level expertise, ensuring safe and correct setup.
              </p>
              <p>
                Once the equipment is in place, we perform additional
                adjustments such as calibration or testing to ensure proper
                functionality. Our meticulous planning and execution guarantee
                smooth delivery and installation, resulting in safe and
                efficient operation of the machinery.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
